(function (exports) {
  exports.smallSpinner = function () {
    return {
      lines: 9,
      length: 5,
      width: 3,
      radius: 1,
      scale: 1,
      corners: 1,
      color: '#000',
      opacity: 0.25,
      rotate: 0,
      direction: 1,
      speed: 1,
      trail: 60,
      fps: 20,
      zIndex: 2e9,
      className: 'spinner',
      top: '50%',
      left: '50%',
      shadow: false,
      hwaccel: false,
      position: 'absolute'
    }
  }

  exports.smallRelativeSpinner = function () {
    return {
      lines: 9,
      length: 5,
      width: 3,
      radius: 1,
      scale: 1,
      corners: 1,
      color: '#000',
      opacity: 0.25,
      rotate: 0,
      direction: 1,
      speed: 1,
      trail: 60,
      fps: 20,
      zIndex: 2e9,
      className: 'spinner',
      top: '100%',
      left: '50%',
      shadow: false,
      hwaccel: false,
      position: 'relative'
    }
  }
}(typeof exports === 'undefined' ? this.ajaxLoadingSpinnerOptions = {} : exports))

